
















































import { computed, defineComponent } from '@vue/composition-api';

import { DBtnInline } from '@shared/druid-kit';

import store from '@/store';
import rootUi from '@/store/root/rootUi';

const rootUiContext = rootUi.context(store);

export default defineComponent({
  components: {
    DBtnInline,
  },
  setup() {
    const isShown = computed(() => rootUiContext.getters.redesignNoticeIsShown);

    const onClick = () => {
      rootUiContext.dispatch('CHANGE_REDESIGN_NOTICE_SHOWN_STATUS', false);
    };

    return {
      isShown,
      onClick,
    };
  },
});
