import { render, staticRenderFns } from "./AppRedesignNoticeCard.vue?vue&type=template&id=020acc9b&scoped=true&"
import script from "./AppRedesignNoticeCard.vue?vue&type=script&lang=ts&"
export * from "./AppRedesignNoticeCard.vue?vue&type=script&lang=ts&"
import style0 from "./AppRedesignNoticeCard.vue?vue&type=style&index=0&id=020acc9b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020acc9b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VImg})
