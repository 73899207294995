<template>
  <div class="clients-catalog-loader rounded-0">
    <v-card
      v-if="$route.name === 'client-catalog' && isShown"
      class="clients-catalog-loader__card rounded-0"
      elevation="0"
    >
      <v-skeleton-loader
        class="clients-catalog-loader__card-img"
        type="image"
        width="100"
        height="100"
      />
      <div class="clients-catalog-loader__card-content">
        <v-skeleton-loader
          type="heading"
          class="clients-catalog-loader__card-title"
        />
        <v-skeleton-loader
          type="text"
          class="clients-catalog-loader__card-descr"
        />
      </div>
    </v-card>
    <div class="clients-catalog-loader__content">
      <ListLoader class="clients-catalog-loader__list" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

import store from '@/store';
import rootUi from '@/store/root/rootUi';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';

const rootUiContext = rootUi.context(store);

export default defineComponent({
  components: {
    ListLoader,
  },
  setup() {
    const isShown = computed(() => rootUiContext.getters.redesignNoticeIsShown);

    return { isShown };
  },
});
</script>

<style lang="scss" scoped>

.clients-catalog-loader {
  background-color: map-get($grey, 'lighten-5');

  &__card {
    position: relative;
    padding-top: 34px;
    padding-right: 77px;
  }

  &__card-img {
    position: absolute;
    top: 50px;
    right: 16px;
  }

  &__card-content {
    padding: 16px 128px 20px 24px;
    background-color: rgba(18, 165, 77, 0.05);
  }

  &__card-title {
    margin-bottom: 20px;

    ::v-deep .v-skeleton-loader__heading {
      background: map-get($shades, 'white') !important;
    }
  }

  &__card-descr {
    margin-bottom: 40px;

    ::v-deep .v-skeleton-loader__text {
      background: map-get($shades, 'white') !important;
    }
  }

  &__content {
    padding: 44px 24px;
  }

  &__list {
    width: 16%;
  }
}
</style>
