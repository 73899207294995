/* eslint-disable max-classes-per-file */

import { Store } from 'vuex';
import { Getters, Mutations, Actions, Context, Module, createComposable, createMapper } from 'vuex-smart-module';

import rootUi from '@/store/root/rootUi';

import auth from '@/store/auth';
import navigation from '@/store/navigation';
import navigationUnapprovedPlacements from '@/store/navigation/navigationUnapprovedPlacements';

import client from '@/store/client';
import clients from '@/store/clients';

import campaign from '@/store/campaign';
import campaignEditor from '@/store/campaignEditor';

import reference from '@/store/reference';

class ModuleState {
  scrollPositions = {};
}

class ModuleGetters extends Getters<ModuleState> {
  get getScrollPosition() {
    return (path: string) => this.state.scrollPositions[path];
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  setScrollPosition({ name, scrollPosition }) {
    this.state.scrollPositions[name] = scrollPosition;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  modules!: {
    auth: Context<typeof auth>;
    reference: Context<typeof reference>;
    navigationUnapprovedPlacements: Context<typeof navigationUnapprovedPlacements>;
  };

  $init(store: Store<unknown>) {
    this.modules = {
      auth: auth.context(store),
      reference: reference.context(store),
      navigationUnapprovedPlacements: navigationUnapprovedPlacements.context(store),
    };
  }

  async INIT() {
    await Promise.all([
      this.modules.auth.dispatch('FETCH_USER'),
      this.modules.reference.modules.metricTypes.dispatch('FETCH_METRIC_TYPES'),
      this.modules.navigationUnapprovedPlacements.dispatch('FETCH_UNAPPROVED_PLACEMENTS_COUNT'),
    ]);
  }
}

const module = new Module({
  modules: {
    ui: rootUi,
    auth,
    navigation,
    client,
    clients,
    campaign,
    campaignEditor,
    reference,
  },
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useRootStore = createComposable(module);

export const rootStoreMapper = createMapper(module);

export default module;
